import { zipObject } from 'lodash-es';

export const MODEL_STATUS_MAP = {
  error: 'error',
  running: 'running',
  success: 'success',
};

const CREATING_SCHEMAS = 'creatingSchemas';

export const MODEL_STEPS = zipObject([CREATING_SCHEMAS], [CREATING_SCHEMAS]);

const CUSTOM = 'custom';
const DELIMITED = 'delimited';
const JSON = 'json';
const XML = 'xml';
const RAW = 'raw';

export const NORMALIZATION_TYPES = zipObject([CUSTOM, DELIMITED, JSON, XML, RAW], [CUSTOM, DELIMITED, JSON, XML, RAW]);
