import { OrgsService } from '~/support/services';

import { RESET_ORGANIZATION, SET_ORGANIZATION } from './mutation-types';

export default {
  async create(_, body) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.create(body);

    return data;
  },

  async createEntitlements(_, body) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.createEntitlements(body);

    return data;
  },

  async deactivateOrg(_, id) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.deactivate(id);

    return data;
  },

  async editImage({ dispatch }, { body, orgId, params }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.updateImage(body, orgId, params);

    await dispatch('fetchOrg', orgId);

    return data;
  },

  async fetchAllOrgs(_, { filter } = {}) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.fetchAll({ filter });

    return data;
  },

  async fetchAllStats() {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.fetchAllStats();

    return data;
  },

  async fetchOrg({ commit }, id) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.fetchOne(id);

    commit(SET_ORGANIZATION, data);

    return data;
  },

  async fetchStatsByOrg(_, id) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.fetchStatsByOrg(id);

    return data;
  },

  async fetchStatsForOwnOrg(_, id) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.fetchStatsForOwnOrg();

    return data;
  },

  async fetchUsers(_, id) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.fetchUsers(id);

    return data;
  },

  async inviteUser(_, { orgId, user }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.inviteUser(orgId, user);

    return data;
  },

  resetOrg({ commit }) {
    commit(RESET_ORGANIZATION);
  },

  async setImage({ dispatch }, { body, orgId, params }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.createImage(body, orgId, params);

    await dispatch('fetchOrg', orgId);

    return data;
  },

  async update({ dispatch }, { orgId, body }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.update(orgId, body);

    await dispatch('fetchOrg', orgId);

    return data;
  },

  async updateSubscriptionPlan({ dispatch }, { orgId, plan }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.updateSubscriptionPlan(orgId, plan);

    await dispatch('fetchOrg', orgId);

    return data;
  },

  async updateSubscriptionPlans({ dispatch }, { orgId, body }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.updateSubscriptionPlans(orgId, body);

    await dispatch('fetchOrg', orgId);

    return data;
  },

  async updateSupplier({ dispatch }, { orgId, body }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.updateSupplier(orgId, body);

    await dispatch('fetchOrg', orgId);

    return data;
  },

  async updateUser(_, { orgId, userId, body }) {
    const service = new OrgsService(useRuntimeConfig().public.orgsBaseUrl);
    const data = await service.updateUser(orgId, userId, body);

    return data;
  },
};
