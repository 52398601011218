import { ASC, DESC } from './misc.js';

export const CATALOG_FILTER_LIMIT = 10;

export const CATALOG_FILTER_TYPES = {
  checkbox: 'checkbox',
  radio: 'radio',
};

export const CATALOG_ICON = 'book-2-line';
export const CATALOG_ROUTE_NAME = 'catalog';
export const CATALOG_DEFAULT_FILTERS = {
  access: '',
  subscribed: false,
  view: '',
};

export const CATALOG_FILTER_OPERATORS = {
  eq: 'eq',
  ilike: 'ilike',
  in: 'in',
};

export const CATALOG_ROUTES = {
  dataProduct: 'catalog-data-products-dataProductId',
  dataProducts: 'catalog-data-products',
  dataset: 'catalog-data-products-dataProductId-datasets-datasetId',
  families: 'catalog-families',
  family: 'catalog-families-familyName',
  frame: 'catalog-data-products-dataProductId-datasets-datasetId-frames-frameId',
  supplier: 'catalog-suppliers-supplierId',
  suppliers: 'catalog-suppliers',
};

export const CATALOG_VIEW_OPTIONS = [
  {
    label: 'Product Families',
    value: 'product-families',
  },
  {
    label: 'Data Products',
    value: 'data-products',
  },
];

export const ORDER = 'order';
export const FIELD = 'field';
export const DATA_TYPE = 'dataType';
export const DESCRIPTION = 'description';
export const X_REF = 'x-ref';
export const P_KEY = 'p-key';
export const SAMPLE = 'sample';

export const CATALOG_PRODUCT_ACCESS_TYPES = {
  commercial: 'VENDOR',
  public: 'PUBLIC',
};

export const CATALOG_SCHEMA_KEYS = {
  dataType: DATA_TYPE,
  description: DESCRIPTION,
  field: FIELD,
  pKey: P_KEY,
  sample: SAMPLE,
  xRef: X_REF,
};

export const CATALOG_DEFAULT_SORT_DIRECTION = ASC.toLowerCase();
export const CATALOG_DEFAULT_SORT_KEYS = {
  family: 'familyName',
  product: 'productName',
  supplier: 'supplier',
};
export const CATALOG_DROPDOWN_OPTION_HIGHLIGHT_BG_COLOR = 'bg-blue-50';
export const CDU_ID_PREFIX = 'Cx';

export const BASE_CATALOG_REQUEST = {
  company: '',
  email: '',
  name: '',
  phone: '',
};

export const CATALOG_CONTACT_CRUX_REQUEST = {
  requestDetails: '',
};

export const CATALOG_PRODUCT_ACCESS_REQUEST = {
  isLicensed: false,
  wouldLikeSupplierIntro: true,
};
export const CATALOG_ICONS_MAP = {
  now: 'flashlight-line',
  request: 'mail-line',
  subscribed: 'checkbox-circle-fill',
};
export const CATALOG_SUPPLIER_REQUEST = {
  requestDetails: '',
};

export const CATALOG_SUPPLIER_LOGO_URL_SUFFIX = '/assets/logo?idType=code';
export const CATALOG_SUPPLIER_COVER_URL_SUFFIX = '/assets/coverImage?idType=code';

export const CATALOG_WINDOW_RESIZE_PAGINATION_DEBOUNCE = 500;

export const CATALOG_FAMILY_DEFAULT_SORT = `${CATALOG_DEFAULT_SORT_KEYS.family}:${CATALOG_DEFAULT_SORT_DIRECTION}`;
export const CATALOG_FAMILY_SORT_OPTION_VALUES = {
  asc: `${CATALOG_DEFAULT_SORT_KEYS.family}:${ASC.toLowerCase()}`,
  desc: `${CATALOG_DEFAULT_SORT_KEYS.family}:${DESC.toLowerCase()}`,
};
export const CATALOG_PRODUCT_DEFAULT_SORT = `${CATALOG_DEFAULT_SORT_KEYS.product}:${CATALOG_DEFAULT_SORT_DIRECTION}`;
export const CATALOG_PRODUCT_SORT_OPTION_VALUES = {
  asc: `${CATALOG_DEFAULT_SORT_KEYS.product}:${ASC.toLowerCase()}`,
  desc: `${CATALOG_DEFAULT_SORT_KEYS.product}:${DESC.toLowerCase()}`,
};
export const CATALOG_SUPPLIER_DEFAULT_SORT = `${CATALOG_DEFAULT_SORT_KEYS.supplier}:${CATALOG_DEFAULT_SORT_DIRECTION}`;
export const CATALOG_SUPPLIER_SORT_OPTION_VALUES = {
  asc: `${CATALOG_DEFAULT_SORT_KEYS.supplier}:${ASC.toLowerCase()}`,
  desc: `${CATALOG_DEFAULT_SORT_KEYS.supplier}:${DESC.toLowerCase()}`,
};
