export const DELETE_WORKFLOW_FRAME = 'DELETE_WORKFLOW_FRAME';
export const MERGE_INFERRED_DATASET_INFO = 'MERGE_INFERRED_DATASET_INFO';
export const REMEMBER_PROFILING_NOTIFICATION_MODAL = 'REMEMBER_PROFILING_NOTIFICATION_MODAL';
export const RESET_DATA_PRODUCT = 'RESET_DATA_PRODUCT';
export const RESET_DATA_PRODUCTS = 'RESET_DATA_PRODUCTS';
export const SET_CONNECTION = 'SET_CONNECTION';
export const SET_CONNECTIONS = 'SET_CONNECTIONS';
export const SET_DATA_PRODUCT = 'SET_DATA_PRODUCT';
export const SET_DATA_PRODUCT_DESTINATIONS = 'SET_DATA_PRODUCT_DESTINATIONS';
export const SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING = 'SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING';
export const SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE = 'SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE';
export const SET_DATA_PRODUCT_HISTORY = 'SET_DATA_PRODUCT_HISTORY';
export const SET_DATA_PRODUCTS = 'SET_DATA_PRODUCTS';
export const SET_WORKFLOW_FRAME_NAME = 'SET_WORKFLOW_FRAME_NAME';
export const SET_DESTINATIONS = 'SET_DESTINATIONS';
export const SET_RESOURCES = 'SET_RESOURCES';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const UPDATE_DATASTORES_MAP = 'UPDATE_DATASTORES_MAP';
export const SET_DATA_PRODUCT_DATASETS = 'SET_DATA_PRODUCT_DATASETS';
